import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Blog = (props) => (
    <Layout>
        <Helmet>
            <title>Victories</title>
            <meta name="description" content="Blog Page" />
        </Helmet>
    

   
 <div id="main" className="alt">

            <section id="one">
                <div className="inner">
   
      Blog Coming Soon...   
   </div>
</section>
</div>
                   
   
                

        
    </Layout>
)

export default Blog

